<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">Danh sách nhiệm vụ</h6>
                  <router-link :to="'/wallet-run/mission/create'">
                    <b-button class="btn btn-light font-weight-bold btn-sm"
                      >Tạo mới
                    </b-button>
                  </router-link>
                </div>
              </template>

              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(status)="data">
                  <b-button
                    v-if="data.item.status == 1"
                    class="btn btn-bg-success"
                    >Active</b-button
                  >
                  <b-button v-else class="btn btn-bg-danger">Inactive</b-button>
                </template>
                <template #cell(action)="data">
                  <div style="display: flex">
                    <router-link
                      :to="'/wallet-run/mission/edit/' + data.item.id"
                    >
                      <b-button pill variant="info" size="sm">Sửa </b-button>
                    </router-link>

                    <b-button
                      @click="destroy(data.item.id)"
                      pill
                      variant="danger"
                      size="sm"
                      >Xóa
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination-nav
                  v-model="currentPage"
                  :link-gen="createLinkPage"
                  :number-of-pages="paginate.totalPage"
                  use-router
                  first-number
                  last-number
                  align="right"
                ></b-pagination-nav>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const WalletRunRepository = RepositoryFactory.get("WalletRun");

export default {
  name: "List.vue",
  mixins: [Common],
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "id",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "name",
          label: "Name",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "code",
          label: "Code",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý nhiệm vụ", route: "/wallet-run/mission" },
    ]);
    this.filter = this.$route.query;
    this.getList();
  },
  methods: {
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        let params = this.filter;
        params.page = this.currentPage;
        params.limit = this.paginate.limit;
        let response = await WalletRunRepository.getList(params);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        if (body.data) {
          this.items = body.data;
          this.currentPage = body.data.data.current_page;
          this.paginate.total = body.data.data.total;
          this.paginate.totalPage = body.data.to;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    async destroy(id) {
      if (confirm("Bạn có chắc xóa nhiệm vụ")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await WalletRunRepository.deleteMission(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },
  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
};
</script>

<style scoped></style>
